"use client";

import { ExternalLinkIcon } from "lucide-react";
import { useLocalStorage } from "usehooks-ts";

import { Translation } from "./translations/component";
import { Button } from "./ui/button";

interface Props {
  disabled?: boolean;
}

export function CookieBanner({ disabled }: Props) {
  const [closed, setClosed] = useLocalStorage("@nextgen/cookie-banner", false);
  if (closed) {
    return null;
  }
  if (disabled) {
    return null;
  }
  return (
    <div className="bg-background ring-secondary fixed bottom-4 right-4 max-w-lg rounded-xl p-6 shadow-2xl ring-1">
      <p className="text-sm leading-6">
        <Translation id="cookie-banner.text-1">
          Vi använder bara nödvändiga cookies.
        </Translation>
      </p>
      <p className="text-sm leading-6">
        <Translation id="cookie-banner.text-2">Läs mer i vår</Translation>{" "}
        <a
          href="/cookie-policy"
          target="_blank"
          className="underline underline-offset-2"
        >
          <Translation id="cookie-banner.text-3">cookiepolicy</Translation>{" "}
          <ExternalLinkIcon className="inline-block h-4 w-4" />
        </a>
        .
      </p>
      <div className="mt-4 flex items-center gap-x-5">
        <Button onClick={() => setClosed(true)} data-testid="cookie-banner-ok">
          <Translation id="common.ok">Okej</Translation>
        </Button>
      </div>
    </div>
  );
}
